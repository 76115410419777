.tabs-v2 {
  > nav {
    align-items: center;
    display: flex;
    margin-bottom: 28px;

    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        border-bottom: 2px solid transparent;
        color: var(--neutral-600);
        cursor: pointer;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        padding: 8px 16px;
        user-select: none;

        &.active {
          border-bottom-color: var(--secondary-500);
          color: var(--dark-100);
          font-weight: 600;
        }
      }
    }
  }
}
