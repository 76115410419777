@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800');

@import './colors.css';

#id {
  margin: 0 auto;
  padding: 0;
  height: 100%;
}

html * {
  font-family: 'Nunito Sans', sans-serif !important;
}

h4 {
  padding: 0 !important;
  margin: 0 !important;
}

.screenView {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(270deg, #255E6D, #2B6E81, #317E92);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 15s ease infinite;
  -moz-animation: AnimationName 15s ease infinite;
  animation: AnimationName 15s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ModalTransparent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Modal a {
  display: block;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  margin: 10px;
}

.Modal a:hover {
  text-decoration: underline;
}

.Input {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-bottom: 1px solid #58a4b0;
  margin: 0 20px;
}

.Input input {
  border: 0;
  background: transparent;
  padding: 2px 10px;
  margin: 10px 4px 0 4px;
  color: #435963;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
}

::placeholder {
  color: #435963;
  font-family: 'Nunito Sans Bold', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.Input input:required {
  box-shadow: none;
}

.Input input:focus ~ label {
  opacity: 1;
}

label {
  font-family: FontAwesome;
  color: white;
  font-size: 14px;
  margin-top: 17px;
  transition: opacity 0.3s ease-in-out;
}

label::before {
  padding: 5px 5px 0 0;
  opacity: 0.5;
}

.login_button {
  position: relative;
  margin: 15px 0 5px 0;
  left: 50%;
  transform: translateX(-50%);
  background: #3eb0ca;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: white;
  width: 85%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.social-signin {
  text-align: center;
}

.social-signin button {
  width: 40%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
}

.social-signin button i {
  font-size: 20px;
  color: white;
}

.social-signin button.fb {
  background: #3a5a98;
  margin-right: 13px;
}

.social-signin button.tw {
  background: #55acee;
}

.bringitback {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  background: #58a4b0;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: white;
  width: 200px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.dashboardUserIcon {
  margin: 10px;
}

.dashboardUserIcon:hover {
  filter: drop-shadow(1px 2px 6px rgba(0, 0, 0, 0.5));
}

.sideBarStyling {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.sideBarLink {
  font-size: 25px;
  text-decoration: none;
  color: #435963;
  padding: 20px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 300;
}

.hr {
  display: block;
  margin-top: 1em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  color: #435963;
  border-width: 0.6px;
}

.date {
  color: #435963;
  font-family: Nunito;
  font-size: 18px;
  font-weight: bold;
  width: 50px;
  height: 100%;
  text-align: left;
  margin-left: 5px;
}

.h3 {
  font-size: 19px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 300;
  color: #435963;
  padding-left: 5px;
}

.h4 {
  font-size: 18px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 300;
  color: #435963;
}

.profileHeader {
  font-size: 28px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 300;
  color: #9ea4a6;
}

.p {
  font-size: 12px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  color: #435963;
  padding-left: 5px;
}

.name {
  font-size: 18px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  color: #435963;
  padding-left: 5px;
}

.strong {
  font-weight: 600;
  font-size: 20px;
  font-family: 'Nunito Sans', sans-serif;
  color: #435963;
}

.userContainer {
  // border: 1px black solid;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.create_job_btn {
  width: 150px;
  height: 34px;
  border-radius: 17px;
  background: #3eb0ca;
  border: none;
  font-family: 'Nunito Sans Bold', sans-serif;
  color: #ffffff;
}

.create_job_pick_user_btn {
  border: none;
  font-family: 'Nunito Sans Bold', sans-serif;
  color: #000000;
}

.create_job_pick_user_btn:hover {
  border: none;
  font-family: 'Nunito Sans Bold', sans-serif;
  background: #f2f2f2;
}

.list_custom_class {
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.5));
  background: lightgray;
}

.btn_toggle {
  background: none;
  height: 50px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 300;
}

.greenRow {
  background-color: #C1F5B9;
}

.redRow {
  background-color: #F8B5B5;
}

.yellowRow {
  background-color: #FDFFC9;
}

.blueRow {
  background-color: #C0E9F2;
}


.pinkRow {
  background-color: #F3C9EC;
}

.orangeRow {
  background-color: #FFDB9A;
}

.profile_image {
  width: 100px;
  height: 100px;
  object-fit: 'cover';
  background-size: 'contain';
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.5));
  border-radius: 50px;
  background-color: white;
  // transform: translate(-30%, 0%);
}

.profile_image_default {
  width: 50px;
  transform: translate(50%, 25%);
}

.profile_image:hover {
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.5);
}

.profile_image_default_circle {
  width: 100px;
  height: 100px;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.5));
  background-color: #3eb0ca;
  border-radius: 100px;
  transform: translate(-30%, 0%);
}

select {
  margin-top: 5px;
  position: relative;
  width: 100px;
  height: 30px;
  outline: none;
}

.profile_image::before {
  color: 'blue';
  transform: translate(-50%, -125%);
}

.global_font {
  font-family: Nunito;
  color: #435963;
}

.card_text_container {
  width: 90%;
  border-bottom: #b0bec5 1px solid;
  margin: 1px 0px;
}

.blue_text {
  font-size: 14px;
  font-family: 'Nunito Sans Bold', sans-serif;
  color: #3eb0ca;
}

.z_index {
  z-index: 1;
  width: 60%;
}

.btn_selected {
  border-radius: 5px;
  width: 100px;
  margin: 0px 25px;
  background-color: #e76738;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.btn_selected:hover {
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.5);
}

.btn_selected:active {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.btn_selected:focus {
  outline: 0;
}

.card {
  height: 300px;
  width: 250px;
  min-width: 200px;
  border: 1px solid black;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  border: 1px solid #efefef;
  border-radius: 5px;
}

.job_user_search_box {
  position: absolute;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid black;
  border: 1px solid #efefef;
  border-radius: 11px;
}

.default_pic_small {
  width: 100px;
  height: 100px;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.3));
  border-radius: 100px;
  background-color: #3eb0ca;
  justify-content: center;
  display: flex;
  align-items: center;
}

label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

.stripe_button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #f4511e;
  font-family: 'Nunito';
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
  width: 100%;
}

form {
  margin-bottom: 20px;
  width: 350px;
}

.stripe_button:hover {
  color: #fff;
  cursor: pointer;
  background-color: rgb(248, 96, 50);
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

input {
  display: block;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: #efefef;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.character {
  line-height: 50px;
  font-size: 36px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 8px;
  color: #435963;
  text-align: center;
  width: 50px;
  margin: 30px 10px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
}

.character--selected {
  color: #435963;
  outline: red 2px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.characters {
  height: 50px;
}

.confirm-container {
  max-width: 350px;
}

/* Style the close button (span) */
.close {
  cursor: pointer;
  position: absolute;
}

.close:hover {
  background: #bbb;
}

.professional_stats_table {
  border-collapse: collapse;

  th, td {
    border: 1px solid #EEE;
    padding: 8px;
    text-align: left;
    font-size: 14px;
  }
}
