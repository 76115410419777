.dropdown-menu {
  > .dropdown-item {
    &:active,
    &.active {
      background-color: var(--neutral-100);
      color: var(--black);
    }
    &:hover {
     background-color: var(--neutral-100);
    }
  }
}
