.general-tab {
  display: grid;
  gap: 20px;
  grid-template-columns: 1.5fr 2fr;
  margin-top: 20px;
  width: 80%;

  > aside {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
