.multiselect {
  display: inline-block;
  position: relative;
  height: auto;
  cursor: pointer;
  text-align: left;
}

.multiselect__selected {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiselect__selected-option {
  margin-right: 4px;
  padding-inline: 4px;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.multiselect__options_wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  min-width: 150px;
  background-color: #ebebea;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 2px 0 0 0;
}

.multiselect__options {
  max-height: 50vh;
  overflow-y: auto;
  list-style-type: none;
  padding: 5px 0;
  margin: 0;
}

.multiselect__option {
  font-size: 12px;
  padding: 3px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.multiselect__option:hover, .multiselect__all:hover {
  background-color: #e0e0e0;
}

.multiselect__option--selected {
  background-color: inherit;
}

.multiselect__all {
  width: 100%;
  display: flex;
  padding: 5px 15px;
  cursor: pointer;
}

.multiselect__option > input,
.multiselect__all > input {
  margin-top: -2px;
  margin-right: 11px;
  box-shadow: none;
}

.multiselect__top_buttons {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.multiselect__bottom_buttons {
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  border-top: 1px solid #ccc;
}

.multiselect__bottom_buttons > .btn {
  background-color: #307F92;
  border-color: #307F92;
}

.multiselect__bottom_buttons > .btn:hover {
  background-color: #2a6c7e;
  border-color: #2a6c7e;
}

.multiselect__option > input, .multiselect__all > input {
  margin-top: 0 !important;
  margin-right: 8px !important;
}
