.character {
    line-height: 50px;
    font-size: 36px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 8px;
    color: #435963;
    text-align: center;
    width: 50px;
    margin: 30px 10px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0 1px 3px, rgba(0, 0, 0, 0.0196078) 0 1px 0;
  }

  .character--selected {
    color: #435963;
    outline: red 2px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.109804) 0 4px 6px, rgba(0, 0, 0, 0.0784314) 0 1px 3px;
  }

  .characters {
    height: 50px;
  }

  .confirm-container {
    max-width: 350px;
  }
