.modal_window{
  position: fixed;
  width: 600px;
  height: 400px;
  background: white;
  left: 50%;
  top: 25%;
  z-index: 1;
  transform: translate(-50%);
  border-radius: 10px;
  border: 1px grey solid;
  padding: 10px;
  filter: blur(0);


}
.modal_title{
  font-size: 20px;
  text-align: center;
}
.modal_text{
  font-family: 'Nunito', sans-serif;
   font-weight: 600;
}
.modal_column{
  width: 100%;
  border-bottom: 1px solid #efefef;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
}
.title_column{
  font-family: 'Nunito', sans-serif;
  text-align: center;
  font-size: 16px;
  color: #070707;
  font-weight: bold;
}
.modal_button{
  bottom: 30px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-evenly;
  width: 100%;
  .cancel{
    color: red;
    background: transparent;
    border: 0;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;


  }
  .confirm{
    color: white;
    font-size: 18px;
    background: #0a833d;
    border-radius: 10px;
    border: none;
    padding: 5px 10px 5px 10px;
    font-family: 'Nunito', sans-serif;

  }
}
.blur{
  pointer-events: none;
  filter: blur(2px);

}
.scroll{
  overflow-y: hidden;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  backdrop-filter: blur(4px);
  
  > .popup_content {
    width: 600px;
    height: 625px;
    background: white;
    border-radius: 10px;
    border: 1px grey solid;
    padding: 10px;
    overflow-y: scroll;
  }
}