.loyalty-card-group {
  width: 100%;
  padding: 0 5%;

  .card {
    margin-bottom: 30px;

    &-title {
      text-align: center;
    }
  }

  .row-block {
    width: 100%;
    border-bottom: 1px solid rgb(239, 239, 239);
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    &__label {
      font-weight: 600;
    }
  }
}
