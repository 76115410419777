.input-number-wrapper {
  display: flex;
  align-items: center;
}

.input-number {
  position: relative;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.input-number input {
  width: 70%;
  padding: 0;
  border: 0;
  text-align: center;
  appearance: none;
  box-shadow: none;
  background: none;
  outline: none;
}

.input-number button {
  cursor: pointer;
  font-weight: bold;
  width: 4ex;
  text-align: center;
  transition: all 0.1s ease 0s;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-radius: 2px;
  border-width: 1px;
}

.input-number button:hover {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.2);
}

.input-number button.minus {
  position: absolute;
  inset: 2px auto 2px 2px;
}

.input-number button.plus {
  position: absolute;
  right: 2px;
  top: 2px;
  bottom: 2px;
}

.input-number-wrapper > .btn {
  background-color: #307f92;
  border-color: #307f92;
}

.input-number-wrapper > .btn:hover {
  background-color: #2a6c7e;
  border-color: #2a6c7e;
}
