.availability-calendar {
  overflow-x: scroll;

  .bg-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -52%);
    z-index: 1;
    &.on-call {
      transform: translate(-52%, -62%);
    }
  }

  .DayPicker-Month {
    margin-left: 0;
    margin-right: 0;
  }

  .DayPicker-Day {
    padding-inline: 0.8em;
  }

  .legend-block {
    display: flex;
    align-items: center;
    gap: 24px;
    padding-inline: 5px;
  }

  .legend-item {
    display: flex;
    align-items: center;
    gap: 7px;
  }
}
