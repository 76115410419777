.list {
  .list-header {
    align-items: center;
    color: var(--neutral-600);
    display: flex;
    font-weight: 700;
    width: 100%;
    border-bottom: 1.5px solid var(--neutral-70);
    padding-block: 8px;
  }

  .list-item {
    align-items: center;
    background-color: transparent;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    padding-inline: 12px;
    transition: 300ms ease;
    width: 100%;

    &:hover {
      background-color: var(--neutral-100);
    }
  }
}
