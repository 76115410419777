.list-item {
  background-color: transparent;
  height: 40px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--neutral-70);

  &.hover-animations {
    cursor: pointer;

    &:hover {
      background-color: var(--neutral-100);
    }
  }
}
