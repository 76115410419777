@import url('https://fonts.googleapis.com/css?family=Nunito:300,300i,400,600,700&display=swap');

.disclaimer {
  font-size: 16px;
  font-family: Nunito;
  font-weight: 300;
  font-style: italic;
  color: #435963;
}

.input_registration {
  border: 1px solid #b0bec5;
  border-radius: 5px;
  width: 100%;
  outline: none;
  height: 42px;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  padding: 0px 0px 0px 10px;
  box-shadow: none;
  margin-left: 0px;
}

.input_phone {
  border-bottom: 1px solid #b0bec5;
  border-radius: 5px;
  width: 100%;
  outline: none;
  height: 42px;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  padding: 0px 0px 0px 5px;
  box-shadow: none;
  margin-left: 0px;
  margin-top: 15px;
}

.input_registration:focus {
  border: 1.5px solid #3eb0ca;
}

.input_phone:focus {
  border-bottom: 1.5px solid #3eb0ca;
  box-shadow: none;
}

textarea {
  width: 98%;
  height: 200px;
  resize: none;
  border-radius: 25px;
  outline: none;
  border: 2px solid #3eb0ca;
  padding: 10px;
  color: #435963;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.input_names {
  height: 24px;
  width: 170px;
  color: #435963;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.input_active_blue {
  height: 24px;
  width: 170px;
  color: #3eb0ca;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.input_active_red {
  height: 24px;
  width: 170px;
  color: #f4511e;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.input_inactive {
  height: 24px;
  width: 170px;
  color: #b0bec5;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.blue_font {
  height: 27px;
  color: #3eb0ca;
  font-family: Nunito;
  font-size: 20px;
  font-weight: bold;
}

.button:hover {
  box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.4);
  cursor: pointer;
}

.button:active {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
}

.modal {
  background-color: rgba(67, 89, 99, 0.64);
  width: 100%;
  min-width: 1140px;
  position: absolute;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.DayPicker {
  font-size: 18px !important;
}

.modal_content {
  width: 550px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.select-box {
  width: 60px;
  height: 42px;
  border: 1px solid #3eb0ca;
  font-size: 20px;
  outline: none;
  color: #435963;
  font-family: Nunito;
  background-color: #fff;
  -webkit-appearance: none;
  background: url('../images/Triangle.png') no-repeat 85% center;
}

.select-box::after {
  background-color: #f4511e;
}

.select-box:hover {
  box-shadow: 0px 2px 3px rgba($color: #000000, $alpha: 0.3);
}

.options {
  text-align: center;
}

.select-title {
  color: #435963;
  font-family: Nunito;
  font-weight: bold;
  padding: 20px;
  width: 170px;
  height: 42px;
  font-size: 18px;
  display: flex;
  border: 1px solid #b0bec5;
  background-color: #fff;
  outline: none;
}

.select-title:focus {
  border: 1.5px solid #3eb0ca;
}

.suggest {
  border-radius: 5px;
  padding: 5px 5px 5px 15px;
  font-family: Nunito;
  font-weight: 600;
}
