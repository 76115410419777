.profile-tab-item {
  table {
    width: 100%;
    margin-bottom: 80px;

    thead {
      tr {
        background-color: #add8e6;

        th {
          padding: 15px;
          font-size: 16px;
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        border: 1px solid #efefef;
        text-align: center;

        td {
          padding: 15px;
          font-size: 15px;
        }
      }
    }
  }
}
