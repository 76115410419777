/* class naming using BEMCSS -> https://getbem.com/introduction/*/

:root {
  --black: #000000;
  --dark-100: #303f46;
  --dark-200: #435963;
  --neutral-70: #f4f4f4;
  --neutral-100: #e9f1f4;
  --neutral-600: #435963;
  --white: #fff;

  --secondary-500: #4fc0cf;
}

.color--dark-100 {
  color: var(--dark-100);
}

.color--dark-200 {
  color: var(--dark-200);
}
