@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');

.component_container {
  background-color: #FAFAFA;
  width: 100%;
  display: flex;
  justify-content: center;
  min-width: 1140px;
}

.floating_container {
  background-color: white;
  width: 100%;
  min-width: 800px;
  margin: 25px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
}

// TODO(lucky): cleanup, should be moved to html/body for global declaration, override if needed
.global_font {
  font-family: 'Nunito', sans-serif;
}

// same as .color--dark-200 from color.css (using color variables)
.f-dark {
  color: #435963;;
}

.f-light {
  color: #6F838D;
}

// TODO(lucky): cleanup, should be using inheritance from html/body instead
.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.button:hover {
  box-shadow: 0 3px 3px rgba($color: #000000, $alpha: 0.4);
  cursor: pointer;
}

.button:active {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5)
}

.bold {
  font-weight: 700;
}

.time_contain {
  width: 100px;
  background-color: #B0BEC5;
  border-radius: 15px;
  padding: 5px;
  text-align: center;
  margin: 15px;
  color: white;
  cursor: pointer;
}

.time_contain:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.time_contain:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
}

.selected_time {
  background-color: #3EB0CA;
}

.common-text-bold {
  color: #435963;
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: bold;
}

.input-blue-border {
  height: 43px;
  max-width: 100%;
  min-width: 50%;
  border-radius: 10px;
  border-width: 1px;
  border-color: #3eb0ca;
  margin-bottom: 10px;
  margin-top: 5px;
}

.input-field {
  color: #435963;
  font-family: Nunito;
  width: 100%;
  height: 42px;
  margin-left: 0;
  font-size: 16px;
  display: flex;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
  background-color: #F4F8FA;
  border: none;
  padding: 0 20px;
  border-radius: 5px;

  &::placeholder {
    color: #AAB4B9;
    font-size: 16px;
  }
}

.table-value-text {
  padding-block: 10px;
  padding-inline: 5px;
  text-align: center
}

.table-title-text {
  font-weight: 600;
  padding-block: 20px;
  padding-inline: 5px;
  text-align: center;
  vertical-align: top;
}

.disabled-arrow-input > input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hover-enlarge {
  transition: transform 0.3s ease;
}

.hover-enlarge:hover {
  transform: scale(1.5);
}

.button_group_container {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  color: #FFFFFF;

  &:before {
    content: '';
    position: absolute;
    height: 36px;
    border-radius: 4px;
    z-index: 2;
    left: 0;
    right: 0;
    box-shadow: 0 2px 3.3px 0 rgba(0, 0, 0, 0.35) inset;
    pointer-events: none;
  }
}

.button_group_container_first_option {
  background: #117a8b;
  padding: 0 12px;
  height: 36px;
  display: flex;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  justify-content: center;
  border: 0.5px solid #10707f;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button_group_container_option {
  background: #117a8b;
  padding: 0 12px;
  height: 36px;
  display: flex;
  border: 0.5px solid #10707f;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button_group_container_last_option {
  background: #117a8b;
  padding: 0 12px;
  height: 36px;
  display: flex;
  border: 0.5px solid #10707f;
  align-items: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.selected_button_group_option {
  background-color: #5bc0de;
}

.semi-bold {
  font-weight: 600;
}

@mixin generate-table-cols($total-cols) {
  @for $i from 1 through $total-cols {
    .table-col-#{$i} {
      width: percentage(calc($i / $total-cols));
    }
  }
}

.row {
  &.gap-8 {
    gap: 8px;
  }
}

@include generate-table-cols(100);

