.job-create {
  .form-field {
    font-size: 20px;
    width: 100%;

    margin-top: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;

    &.form-field__vertical {
      flex-direction: column;

      > label {
        flex-direction: column;
      }

      .label-title {
        width: 100%;
      }
    }

    &.form-field__split {
      .form-field-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    .form-field-content {
      width: 100%;
    }

    > button {
      appearance: none;
      background: inherit;
      margin: 0;
      border: 0;
      text-align: inherit;

      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    select, input:not([type=checkbox]), .multiselect {
      margin-top: 5px;
      height: 50px;
      font-size: inherit;
    }

    .multiselect {
      align-content: center;
    }

    label {
      color: inherit;
      font-size: inherit;
      width: 100%;
      margin-top: 0;

      display: flex;
      align-items: center;
    }

    .label-title {
      width: 20%;

      + * {
        flex: 1;
        font-size: inherit;
      }
    }

    .label-optional {
      font-weight: 400;
    }
  }
}
