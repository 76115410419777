.profile-payment-dnt {
  max-width: 1200px;
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 100px;

  &-body {
    margin: 25px;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-title {
    color: #435963;
  }

  .actions {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-evenly;

    button {
      width: 100px;
    }
  }
}
