.job-details-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  justify-content: center;
  max-width: 800px;
  margin-top: 50px;

  .container {
    width: 100%;
  }

  .title {
    text-align: center;
  }

  .row {
    width: 100%;
    border-bottom: 1px solid #efefef;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;

    // override bootstrap.css
    margin-left: 0;
    margin-right: 0;

    // override utilities.less
    &::before, &::after {
      display: none;
    }

    &-title {
      font-weight: 600;
    }
  }

  .dynamic-links {
    text-align: center;
  }
}
